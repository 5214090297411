import tenantsQuery from "./graphql/tenants_query.graphql";
import {runQuery} from "@/api/query";

export const queryTenants = async (nextPageToken = null) => {
    const result = await runQuery(tenantsQuery, {nextPageToken});
    const tenants = result ? result.tenants.edges.map((edge) => edge.node) : [];
    const hasNextPage = result ? result.tenants.pageInfo.hasNextPage : false;
    const newNextPageToken = result ? result.tenants.pageInfo.endCursor : null;
    return {tenants, hasNextPage, nextPageToken: newNextPageToken};
};
