import {createLogger, createStore} from "vuex";
import users from "./modules/users";
import device from "./modules/device";
import {ownerFilter, parseQuery, quickFilters} from "@/lib/search";
import {useRoute} from "vue-router";
import {queryTenants} from "@/api/tenants";
import auth from "@/store/modules/auth";

const debug = process.env.NODE_ENV !== "production";

const notificationTimeout = 10; //seconds

const state = () => ({
  searchQueryData: {},
  searchQuery: "",
  quickFilters,
  ownerFilters: [],
  notification: null,
  tenants: null,
});

const getters = {
  queryByHash: (state) => (hash) => {
    let filterDefinition = state.quickFilters.find((filter) => filter.hash === hash);
    if (!filterDefinition) {
      filterDefinition = state.ownerFilters.find((filter) => filter.hash === hash);
    }
    return filterDefinition ? filterDefinition.query : null;
  },
  isFilterActive: (state, getters) => (hash) => {
    const route = useRoute();
    if (route.name !== "devices") return false;
    const query = getters.queryByHash(hash);
    const filterSearchData = parseQuery(query, state.tenants);
    return JSON.stringify(filterSearchData) === JSON.stringify(state.searchQueryData);
  },
  searchQueryData: (state) => {
    return state.searchQueryData;
  },
  tenants: (state) => {
    return state.tenants;
  },
};

const actions = {
  async search({ commit, state }, searchQuery) {
    commit("setSearchQuery", searchQuery);
    commit("setSearchQueryData", parseQuery(searchQuery, state.tenants));
  },
  async setFilter({ commit, state, getters }, filterQuery) {
    const query = getters.queryByHash(filterQuery);
    commit("setSearchQuery", query);
    commit("setSearchQueryData", parseQuery(query, state.tenants));
  },
  async setQuickFilterStats({ commit }, quickFilterWithStats) {
    commit("setQuickFilter", quickFilterWithStats)
  },
  async loadTenants({ commit, state }) {
    if (!state.tenants) {
      let hasNextPage = true;
      let nextPageToken = null;
      let tenants = [];
      while (hasNextPage) {
        const result = await queryTenants(nextPageToken);
        hasNextPage = result.hasNextPage;
        nextPageToken = result.nextPageToken;
        tenants = tenants.concat(result.tenants);
        if(tenants.length > 50)
          tenants.sort((a, b) => a.name.localeCompare(b.name))
        commit("setTenants", tenants);
      }
    }
  },
  async createOwnerFilters({commit, dispatch, state}) {
    await dispatch("loadTenants");
    commit(
      "setOwnerFilters",
      state.tenants.map((tenant) => ownerFilter(tenant))
    );
  },
  async showNotification({commit}, notification) {
    commit("clearNotification", notification);

    notification.timeoutId = setTimeout(() => {
      commit("clearNotification");
    }, notificationTimeout * 1000);

    commit("showNotification", notification);
  },
};

const mutations = {
  setTenants(state, tenants) {
    state.tenants = tenants;
  },
  setStatistics(state, statistics) {
    state.statistics = statistics;
  },
  setQuickFilter(state, filter) {
    state.quickFilters = filter
  },
  setSearchQueryData(state, searchQueryData) {
    state.searchQueryData = searchQueryData;
  },
  setSearchQuery(state, searchQuery) {
    state.searchQuery = searchQuery;
  },
  setOwnerFilters(state, ownerFilters) {
    state.ownerFilters = ownerFilters;
  },
  setErrorFilters(state, errorFilter) {
    state.errorFilter = errorFilter;
  },
  showNotification(state, notification) {
    state.notification = notification;
  },
  clearNotification(state) {
    if (state.notification && state.notification.timeoutId) {
      clearInterval(state.notification.timeoutId);
    }
    state.notification = null;
  },
};

export default createStore({
  modules: {
    users,
    device,
    auth,
  },
  state,
  getters,
  actions,
  mutations,
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
