<template>
  <div class="flex flex-col flex-1 min-h-0 py-6">
    <div class="relative flex-1 px-4 sm:px-6">
      <h1 class="mb-5 text-lg font-medium leading-6 text-gray-900">Set devices owner</h1>
      <div>
        <label for="ownerName" class="block text-sm font-medium text-gray-700">Owner name</label>
        <Combobox as="div" v-model="selectedOwner">
          <ComboboxLabel class="block text-sm font-medium leading-6 text-gray-900">{{ $t("device.form.ownerName") }}</ComboboxLabel>
          <div class="relative mt-2">
            <ComboboxInput class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" @change="query = $event.target.value" :display-value="(person) => person?.name" />
            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
              <ChevronDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
            </ComboboxButton>

            <ComboboxOptions v-if="filteredOwner.length > 0" class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <ComboboxOption v-for="owner in filteredOwner" :key="owner.id" :value="owner" as="template" v-slot="{ active, selected }">
                <li :class="['relative cursor-default select-none py-1 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                  <span :class="['block truncate', selected && 'font-semibold']">
                    {{ owner.name }}
                  </span>

                  <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                    <CheckIcon class="w-5 h-5" aria-hidden="true" />
                  </span>
                </li>
              </ComboboxOption>
            </ComboboxOptions>
          </div>
        </Combobox>
      </div>

    </div>
  </div>
  <div class="flex justify-end flex-shrink-0 px-4 py-4">
    <button type="button" class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="$emit('close')">
      Cancel
    </button>
    <button type="submit" :disabled="!isValid" @click="alertOpen = true" class="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50">
      Save
    </button>
  </div>
  <SimpleAlert @cancel="alertOpen = false" @approve="approve" :open="alertOpen" title="Set owner?" description="Are you sure you want to set this owner name to all selected devices? All existing owners will be overwritten. This action cannot be undone." action="Apply"></SimpleAlert>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import SimpleAlert from "@/components/modals/SimpleAlert";
import { useI18n } from "vue-i18n";
import { Combobox, ComboboxButton, ComboboxInput, ComboboxLabel, ComboboxOption, ComboboxOptions, } from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/outline";

export default {
  components: {
    SimpleAlert,
    Combobox,
    ComboboxLabel,
    ComboboxButton,
    ComboboxOptions,
    ComboboxOption,
    ComboboxInput,
    CheckIcon, ChevronDownIcon
  },
  emits: ["close"],
  props: ["deviceSelection"],

  setup(props, { emit }) {
    const alertOpen = ref(false);
    const isValid = computed(() => "id" in selectedOwner.value);
    const store = useStore();
    const { t } = useI18n();
    const ownerList = computed(() => store.getters.tenants)

    const query = ref('')
    const selectedOwner = ref({});
    const filteredOwner = computed(() =>
      query.value === ''
        ? ownerList.value
        : ownerList.value.filter((owner) => {
          return owner.name.toLowerCase().includes(query.value.toLowerCase())
        })
    )


    return {
      isValid,
      alertOpen,
      ownerList,
      selectedOwner,
      filteredOwner,
      query,
      async approve() {
        alertOpen.value = false;
        await store.dispatch("device/batchUpdateOwner", {
          deviceIds: props.deviceSelection,
          tenant: selectedOwner.value,
        });

        await store.dispatch("showNotification", {
          title: t("notifications.mutations.success"),
          text: t("notifications.mutations.delivering"),
          type: "info",
        });
        emit("close");
      },
    };
  },
};
</script>
