<template>
  <h1 class="pt-6 mb-2 font-medium text-left text-gray-900">{{ $t("alarm.header.alertTriggers") }}</h1>
  <dd class="mt-1 text-sm text-gray-900">
    <ul class="">
      <li class="flex flex-col items-center justify-between py-3 pr-4 text-sm">
        <div class="flex w-full">
          <Toggle v-model="triggeredByVoice" :disabled="!isEditAllowed" :caption="$t('alarm.form.triggerByVoice')" />
        </div>
        <div v-if="triggeredByVoice" class="flex items-center justify-between w-full pt-3 pl-5 text-sm">
          <Toggle v-model="triggeredByVoiceOnBattery" :disabled="!isEditAllowed" :caption="$t('alarm.form.triggerByVoiceOnBattery')" :hideIcon="true" :subtitle="triggeredByVoiceOnBattery
            ? $t('alarm.form.triggerByVoiceOnBatteryActive')
            : $t('alarm.form.triggerByVoiceOnBatteryInactive')
            ">
            <template v-slot:tooltip>
              <div v-if="!triggeredByVoiceOnBattery" class="relative px-2 text-sm font-semibold text-left text-gray-900 group">
                <InformationCircleIcon class="w-5 text-gray-500 group hover:text-cyan-800" aria-hidden="true" />
                <div class="absolute z-50 right-2.5 invisible sm:h-44 sm:w-96 p-3 pl-4 mt-2 origin-top bg-white divide-y divide-gray-100 rounded-md shadow-lg group-hover:visible ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {{ $t("alarm.tooltip.triggerByVoiceOnBatteryActive") }}
                </div>
              </div>
              <div v-else class="relative px-2 text-sm font-semibold text-left text-gray-900 group">
                <ExclamationIcon class="w-5 text-red-500 group hover:text-cyan-800" aria-hidden="true" />

                <div class="absolute z-50 right-2.5 invisible sm:w-80 sm:h-40 p-3 pl-4 mt-2 origin-top bg-white divide-y divide-gray-100 rounded-md shadow-lg group-hover:visible ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {{ $t("alarm.tooltip.triggerByVoiceOnBatteryInactive") }}
                </div>
              </div>
            </template>
          </Toggle>
        </div>
      </li>

      <li class="flex items-center justify-between py-3 pr-4 text-sm">
        <Toggle v-model="triggeredByTouch" :disabled="!isEditAllowed" :caption="$t('alarm.form.triggerByTouch')" />
      </li>
      <li class="flex items-center justify-between py-3 pr-4 text-sm">
        <Toggle v-model="triggeredByBracelet" :disabled="!isEditAllowed" :caption="$t('alarm.form.triggerByBracelet')" />
      </li>
    </ul>
  </dd>
</template>

<script>
import { computed, ref, watch } from "vue";
import Toggle from "@/components/ToggleButton";
import { hasDeviceTenantPermission, USER_PERMISSIONS } from "@/lib/userHasPermission";
import { ExclamationIcon, InformationCircleIcon } from "@heroicons/vue/solid";
import { useStore } from "vuex";

export default {
  components: {
    Toggle,
    InformationCircleIcon,
    ExclamationIcon,
  },

  props: ["refresh"],
  emits: ["updateConfig"],

  setup(props, { emit }) {
    const store = useStore();
    const device = computed(() => store.state.device.current);

    const triggeredByVoice = ref(device.value.configV2.alarm_app.triggers.voice);
    const triggeredByTouch = ref(device.value.configV2.alarm_app.triggers.touch);
    const triggeredByBracelet = ref(device.value.configV2.alarm_app.triggers.fall);
    const triggeredByVoiceOnBattery = ref(device.value.configV2.system.low_power_mode.enabled); //TODO check if this is correct and even working?
    const isEditAllowed = computed(() => hasDeviceTenantPermission(USER_PERMISSIONS.ADMIN));

    watch([triggeredByVoice, triggeredByTouch, triggeredByBracelet, triggeredByVoiceOnBattery], () => {
      emit("updateConfig", {
        triggeredByVoice: triggeredByVoice.value,
        triggeredByTouch: triggeredByTouch.value,
        triggeredByBracelet: triggeredByBracelet.value,
        triggeredByVoiceOnBattery: triggeredByVoiceOnBattery.value,
      });
    });

    watch(device, () => {
      if (device.value) {
        reset();
      }
    });

    watch(() => props.refresh, () => {
      if (props.refresh) {
        reset();
      }
    })

    const reset = () => {
      triggeredByVoice.value = device.value.configV2.alarm_app.triggers.voice;
      triggeredByTouch.value = device.value.configV2.alarm_app.triggers.touch;
      triggeredByBracelet.value = device.value.configV2.alarm_app.triggers.fall;
      triggeredByVoiceOnBattery.value = device.value.configV2.system.low_power_mode.enabled;
    }

    return {
      triggeredByVoice,
      triggeredByTouch,
      triggeredByBracelet,
      triggeredByVoiceOnBattery,
      isEditAllowed,
    };
  },
};
</script>
