import {
  queryDevice,
  queryDevices,
  queryNetworkScans,
  updateOwner,
  createConfigTemplate,
  updateConfigTemplate,
  deleteConfigTemplate,
  queryAggregations,
  updateConfigV2,
  queryConfigTemplates,
} from '@/api/device';

const state = () => ({
  all: new Map (),
  current: {},
  loading: false,
  stateSummaryAggregation: {},
});

const actions = {
  async getDevices ({commit, state, rootGetters}, forceReload) {
    if (!forceReload && state.all.size) {
      return;
    }
    commit ('setLoading', true);
    let hasNextPage = true;
    let nextPageToken = null;
    let devices = [];
    let queryData = rootGetters.searchQueryData;
    while (hasNextPage) {
      const result = await queryDevices (nextPageToken, queryData);
      if (queryData !== rootGetters.searchQueryData) {
        break;
      }
      hasNextPage = result.hasNextPage;
      nextPageToken = result.nextPageToken;
      devices = devices.concat (result.devices);
      commit ('setDevices', devices);
      commit ('setLoading', false);
    }
  },

  async getAggregations({commit, rootGetters}) {
    let queryData = rootGetters.searchQueryData;
    const aggregation = await queryAggregations (queryData);
    if (aggregation && aggregation.deviceStateAggregations)
      commit (
        'setStateSummaryAggregation',
        aggregation.deviceStateAggregations
      );
  },

  async getDevice ({commit}, serialNumber) {
    commit ('setLoading', true);

    const device = await queryDevice (serialNumber);

    commit ('setDevice', device);
    commit ('setLoading', false);
  },

  async reloadDevice ({commit}, serialNumber) {
    commit ('setLoading', true);
    const device = await queryDevice (serialNumber);
    commit ('setDevice', device);
    commit ('setLoading', false);
    return true;
  },

  async reloadNetworkScans ({commit}, deviceId) {
    const networkScans = await queryNetworkScans (deviceId);
    commit ('updateNetworkScans', networkScans);
  },

  async queryConfigTemplates({commit}) {
    const configTemplates = await queryConfigTemplates ();
    commit ('setConfigTemplates', configTemplates);
  },

  async createConfigTemplate ({commit}, payload) {
    commit ('setLoading', true);
    const ret = await createConfigTemplate (payload.template);
    commit ('setLoading', false);
    if (ret && ret.template.create.ok) return true;
    return false;
  },

  async updateConfigTemplate ({commit}, payload) {
    commit ('setLoading', true);
    const ret = await updateConfigTemplate (payload.template);
    commit ('setLoading', false);
    if (ret && ret.template.update.ok) return true;
    return false;
  },

  async deleteConfigTemplate ({commit}, payload) {
    commit ('setLoading', true);
    const ret = await deleteConfigTemplate (payload.templateId);
    commit ('setLoading', false);
    if (ret && ret.template.delete.ok) return true;
    return false;
  },

  async updateOwner ({commit}, payload) {
    const {deviceId, tenant} = payload;
    await updateOwner ([deviceId], tenant.id);
    commit ('setOwner', tenant);
  },

  async batchUpdateOwner ({commit}, payload) {
    const {deviceIds, tenant} = payload;
    await updateOwner (deviceIds, tenant.id);
    commit ('batchSetOwner', payload);
  },

  async updateConfig ({commit, state}, data) {
    commit('setConfig', data);
    return await updateConfigV2 (state.current.id, data.data);
  },
};

const mutations = {
  setLoading (state, loading) {
    state.loading = loading;
  },

  setDevices (state, devices) {
    state.all.clear ();
    devices.forEach (device => {
      state.all.set (device.id, device);
    });
  },

  setStateSummaryAggregation (state, aggregation) {
    state.stateSummaryAggregation = aggregation;
  },

  setDevice (state, device) {
    state.current = {...device};
  },

  updateNetworkScans (state, networkScans) {
    state.current.networkScans = networkScans;
  },

  setConfigTemplates (state, configTemplates) {
    state.current.configTemplates = configTemplates;
  },

  setConfig (state, data) {
    state.current.configV2 = data.data;
  },

  setOwner (state, {tenant}) {
    state.current.owner = tenant;
  },

  batchSetOwner (state, {deviceIds, tenant}) {
    deviceIds.forEach (deviceId => {
      const device = state.all.get (deviceId);
      device.owner = tenant;
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
