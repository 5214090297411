import {
  HomeIcon,
  // StatusOfflineIcon,
  // StatusOnlineIcon,
  ChartBarIcon,
  UserIcon,
  CalendarIcon,
  // HeartIcon,
} from "@heroicons/vue/solid";
import * as searchQuery from "search-query-parser";
import i18n from "@/i18n";

const extractUniqueInput = (input, length) => {
  const criteria = input
    .split(" ")
    .filter((e) => e)
    .filter((e) => e.length <= length)
    .map((e) => (e.length < length ? e.concat("*") : e));
  const uniqueCriteria = new Set(criteria);
  return uniqueCriteria.size > 0 ? [...uniqueCriteria] : null;
};

export const filterKeywords = ["is", "has", "status", "owner", "stateFilter"];

export const quickFilters = [
  {
    name: i18n.global.t("filters.all"),
    hash: "#all",
    query: "",
    icon: HomeIcon,
  },
  {
    name: i18n.global.t("filters.operationStatus"),
    icon: ChartBarIcon,
    subItems: [
      {
        name: i18n.global.t("filters.allOk"),
        color: "green",
        query: "stateFilter:allFine",
        amount: "-",
      },
      {
        name: i18n.global.t("filters.offline"),
        color: "orange",
        query: "stateFilter:offline",
        amount: "-",
      },
      {
        name: i18n.global.t("filters.deviceNotifications"),
        subItems: [
          {
            name: i18n.global.t("filters.off"),
            color: "yellow",
            query: "stateFilter:switchedOff",
            amount: "-",
          },
          {
            name: i18n.global.t("filters.batteryPowered"),
            color: "orange",
            query: "stateFilter:batteryPowered",
            amount: "-",
          },
          {
            name: i18n.global.t("filters.batteryEmpty"),
            color: "red",
            query: "stateFilter:batteryEmpty",
            amount: "-",
          },
        ],
      },
      {
        name: i18n.global.t("filters.braceletNotifications"),
        subItems: [
          {
            name: i18n.global.t("filters.batteryLow"),
            query: "stateFilter:batteryLow",
            color: "orange",
            amount: "-",
          },
          {
            name: i18n.global.t("filters.braceletOutOfRange"),
            query: "stateFilter:braceletOutOfRange",
            color: "red",
            amount: "-",
          },
        ],
      },
    ],
  },
  {
    name: i18n.global.t("filters.dailyCheckIn"),
    icon: CalendarIcon,
    subItems: [
      {
        name: i18n.global.t("filters.dailyCheckInActive"),
        query: "stateFilter:dailyCheckInActive",
        color: "green",
        amount: "-",
        subItems: [
          {
            name: i18n.global.t("filters.dailyCheckInMissing"),
            query: "stateFilter:dailyCheckInMissing",
            color: "red",
            amount: "-",
          },
        ],
      },
      {
        name: i18n.global.t("filters.dailyCheckInPaused"),
        query: "stateFilter:dailyCheckInPaused",
        color: "yellow",
        amount: "-",
        subItems: [
          {
            name: i18n.global.t("filters.dailyCheckInByUser"),
            query: "stateFilter:dailyCheckInByUser",
            color: "yellow",
            amount: "-",
          },
          {
            name: i18n.global.t("filters.dailyCheckInPlatform"),
            query: "stateFilter:dailyCheckInPlatform",
            color: "yellow",
            amount: "-",
          },
        ],
      },
      {
        name: i18n.global.t("filters.dailyCheckInDeactivated"),
        query: "stateFilter:dailyCheckInDeactivated",
        amount: "-",
      },
    ],
  },

];

export const ownerFilter = (owner) => {
  return {
    name: owner.name,
    hash: `#owner/${owner.name}`,
    query: `owner:"${owner.name}"`,
    icon: UserIcon,
    id: owner.id,
  };
};

export const quickFilterColor = (hash) => {
  const onlineColor = ["text-green-500"];
  const offlineColor = ["text-red-500"];
  const defaultColor = ["text-cyan-200"];

  if (hash === "#offline") {
    return offlineColor;
  } else if (hash === "#online") {
    return onlineColor;
  } else {
    return defaultColor;
  }
};

/*
Normalize query data to always be an object containing the "text" property
 */
export const parseQuery = (query, tenants) => {
  const queryData = searchQuery.parse(query, { keywords: filterKeywords });
  let queryFilter = {};

  if (typeof queryData === "string") {
    const uniqueSerials = extractUniqueInput(queryData, 8);
    const uniqueBraceletsIds = extractUniqueInput(queryData, 12);
    const uniqueSCAIPControllerIds = extractUniqueInput(queryData, 99);
    if (uniqueSerials) {
      queryFilter.serialNumbers = [...uniqueSerials];
    }
    if (uniqueBraceletsIds) {
      queryFilter.braceletIds = [...uniqueBraceletsIds];
    }
    if (uniqueSCAIPControllerIds) {
      queryFilter.SCAIPControllerIds = [...uniqueSCAIPControllerIds];
    }
  } else if (queryData !== null && queryData !== undefined) {
    if ("text" in queryData) {
      const uniqueSerials = extractUniqueInput(queryData.text, 8);
      const uniqueBraceletsIds = extractUniqueInput(queryData.text, 12);
      const uniqueSCAIPControllerIds = extractUniqueInput(queryData.text, 99);
      if (uniqueSerials) {
        queryFilter.serialNumbers = [...uniqueSerials];
      }
      if (uniqueBraceletsIds) {
        queryFilter.braceletIds = [...uniqueBraceletsIds];
      }
      if (uniqueSCAIPControllerIds) {
        queryFilter.SCAIPControllerIds = [...uniqueSCAIPControllerIds];
      }
    }
    if ("owner" in queryData && !(tenants in [null, undefined])) {
      if (!Array.isArray(queryData.owner)) {
        const ownerCandidate = tenants.find((t) => t.name === queryData.owner);
        if (ownerCandidate !== undefined) {
          queryFilter.ownerIds = [ownerCandidate.id];
        }
      } else {
        queryFilter.ownerIds = queryData.owner.flatMap((ownerName) =>
          tenants.find((t) => t.name === ownerName) ? tenants.find((t) => t.name === ownerName).id : []
        );
      }
    }
    if ("is" in queryData && !Array.isArray(queryData.is)) {
      switch (queryData.is) {
        case "online":
          queryFilter.isOnline = true;
          break;
        case "offline":
          queryFilter.isOnline = false;
          break;
      }
    }
    if ("has" in queryData) {
      if (Array.isArray(queryData.has)) {
        if (queryData.has.includes("bracelet")) {
          queryFilter.hasBracelet = true;
        }
      } else {
        if (queryData.has === "bracelet") {
          queryFilter.hasBracelet = true;
        }
      }
    }
    if ("dailyCheck" in queryData) {
      queryFilter.dailyCheckInStates = [];
      if (Array.isArray(queryData.dailyCheck)) {
        queryFilter.dailyCheckInStates = [...queryData.dailyCheck];
        queryFilter.isOnline = true;
      } else {
        queryFilter.dailyCheckInStates = [queryData.dailyCheck];
        queryFilter.isOnline = true;
      }
    }
    if ("braceletBattery" in queryData) {
      queryFilter.braceletBatteryStates = [];
      if (Array.isArray(queryData.braceletBattery)) {
        queryFilter.braceletBatteryStates = [...queryData.braceletBattery];
      } else {
        queryFilter.braceletBatteryStates = [queryData.braceletBattery];
      }
    }
    if ("braceletPresence" in queryData) {
      queryFilter.braceletPresenceStates = [];
      if (Array.isArray(queryData.braceletPresence)) {
        queryFilter.braceletPresenceStates = [...queryData.braceletPresence];
      } else {
        queryFilter.braceletPresenceStates = [queryData.braceletPresence];
      }
    }
    if ("powerSupply" in queryData) {
      queryFilter.powerSupplyStates = [];
      if (Array.isArray(queryData.powerSupply)) {
        queryFilter.powerSupplyStates = [...queryData.powerSupply];
      } else {
        queryFilter.powerSupplyStates = [queryData.powerSupply];
      }
    }
    if ("cloudPresence" in queryData) {
      queryFilter.cloudReportedDevicePresenceStates = [];
      if (Array.isArray(queryData.cloudPresence)) {
        queryFilter.cloudReportedDevicePresenceStates = [...queryData.cloudPresence];
      } else {
        queryFilter.cloudReportedDevicePresenceStates = [queryData.cloudPresence];
      }
    }
    if ("devicePresence" in queryData) {
      queryFilter.deviceReportedDevicePresenceStates = [];
      if (Array.isArray(queryData.devicePresence)) {
        queryFilter.deviceReportedDevicePresenceStates = [...queryData.devicePresence];
      } else {
        queryFilter.deviceReportedDevicePresenceStates = [queryData.devicePresence];
      }
    }
    if ("stateFilter" in queryData) {
      queryFilter.stateFilter = [];
      if (Array.isArray(queryData.stateFilter)) {
        queryFilter.stateFilter = [...queryData.stateFilter];
      } else {
        queryFilter.stateFilter = [queryData.stateFilter];
      }
    }
  }

  return queryFilter;
};
