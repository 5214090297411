// import i18n from "@/i18n";

const state = {
    user: null,
    loading: false,
};

const getIdToken = (state) => {
    if(
            state.user
            && state.user.signInUserSession
            && state.user.signInUserSession.idToken
            && state.user.signInUserSession.idToken.payload
        ) {
        return state.user.signInUserSession.idToken.payload
    }
}

const getters = {
    isSuperAdmin(state) {
        const idToken = getIdToken(state)
        return idToken && idToken["custom:superadmin"] == "true";
    },
    userRoles(state) {
        const idToken = getIdToken(state)
        if(idToken && "custom:roles" in idToken) {
            return idToken["custom:roles"].split("|")
        }
        return [];
    }
}

const actions = {};

const mutations = {
    setUser(state, user) {
        state.user = user;
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
