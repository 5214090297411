<template>
  <svg width="700pt" height="700pt" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink">
    <g>
      <path
          d="m590.71 210h-11.113v-9.5859c0-17.922-19.047-26.812-33.383-26.812h-192.29c-8.0312-16.801-22.922-32.469-41.094-40.141l-12.34-87.973c-2.5078-17.859-18.008-31.488-36.047-31.488h-88.684c-18.031 0-33.531 13.836-36.047 31.695l-12.363 87.359c-27.559 11.051-46.156 37.605-46.156 70.023v153.85c0 33.824 19.801 63.668 47.059 76.324l11.402 81.059c2.5156 17.859 18.07 31.695 36.102 31.695h88.688c18.031 0 33.531-13.832 36.047-31.695l11.262-79.922c23.238-9.7266 41.066-31.18 46.793-56.379h187.66c15.496 0 33.383-19.477 33.383-34.738v-10.07h11.117c15.227 0 28.086-11.664 28.086-26.879l-0.003906-68.988c0.007812-15.207-12.855-27.332-28.078-27.332zm-420.09-159.57c0.36328-2.5508 2.5742-5.6289 5.1445-5.6289h88.688c2.5703 0 4.7773 3.0781 5.1406 5.6289l10.848 78.371h-120.67zm98.973 459.68c-0.36328 2.5508-2.5742 5.0859-5.1445 5.0859h-88.684c-2.5664 0-4.7773-2.5352-5.1406-5.0859l-9.832-70.512h118.63zm13.555-101.31h-126.08c-1.3984 0-45.07-6.3242-45.07-51.871v-153.85c0-25.648 19.32-43.477 45.07-43.477h126.09c13.41 0 25.742 5.6016 34.301 14h-55.57c-11.266 0-23.887 7.6328-23.887 26.812v142.85c0 20.352 9.2852 34.738 23.883 34.738h64.008c-9.6992 27.996-41.395 30.797-42.742 30.797zm-14.285-61.602c-0.10547-2.8008-0.13281-142.8-0.13281-142.8h6.6406l78.023 142.8zm279.94-134.31v128.37c0 1.6016-2.7031 3.1406-4.3164 5.9414h-155.39l-78.02-142.8h228.82c3.4922 0 9.2969 0.99219 8.9062 8.4922zm39.199 89.508h-8.3984v-61.598h8.3984z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'BraceletBatteryIcon',
}
</script>

<style scoped>
</style>
