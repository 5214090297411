<template>
  <div class="flex items-center flex-shrink-0 h-16 px-4">
    <img class="w-auto h-8" src="../assets/logo.svg" alt="CARU" />
  </div>
  <div class="flex flex-col flex-1 overflow-y-auto w-60">
    <nav class="flex-1 px-3 py-4 bg-gray-800 divide-y divide-gray-700">
      <div class="space-y-1">
        <template v-for="quickFilter in quickFilters" v-bind:key="quickFilter.name">
          <template v-if="quickFilter.subItems">
            <NavigationTree :nodes="quickFilter.subItems" :label="quickFilter.name" :hash="quickFilter.hash" :icon="quickFilter.icon" :amount="quickFilter.amount" :color="quickFilter.color" :query="quickFilter.query" :depth="0">
            </NavigationTree>

          </template>

          <template v-else>

            <template v-if="quickFilter.hash">
              <router-link to="/" :class="[
                isFilterActive(quickFilter.hash)
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'group flex items-center px-2 py-1 text-sm font-medium rounded-md cursor-pointer',
              ]" v-if="quickFilter.hash == '#all'">
                <component :is="quickFilter.icon" :class="['mr-2', 'flex-shrink-0', 'h-4', 'w-4', ...quickFilterColor(quickFilter.hash)]" aria-hidden="true" />
                {{ quickFilter.name }}
              </router-link>

              <router-link :to="{ name: 'devices', hash: quickFilter.hash }" :class="[
                isFilterActive(quickFilter.hash)
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'group flex items-center px-2 py-1 text-sm font-medium rounded-md cursor-pointer',
              ]" v-else>
                <component :is="quickFilter.icon" :class="['mr-2', 'flex-shrink-0', 'h-4', 'w-4', ...quickFilterColor(quickFilter.hash)]" aria-hidden="true" />

                {{ quickFilter.name }}
              </router-link>

            </template>

            <div v-else :class="[
              isFilterActive(quickFilter.hash)
                ? 'bg-gray-900 text-white'
                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
              'group flex items-center px-2 py-1 text-sm font-medium rounded-md pointer',
            ]">
              {{ quickFilter.name }}
            </div>
          </template>
        </template>
      </div>

      <div class="pt-6 mt-6">
        <div class="space-y-1">
          <router-link :to="{ name: 'devices', hash: ownerFilter.hash }" :class="[
            isFilterActive(ownerFilter.hash)
              ? 'bg-gray-900 text-white'
              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
            'group flex items-center px-2 py-1 text-sm font-medium rounded-md pointer',
          ]" v-for="ownerFilter in ownerFilters" v-bind:key="ownerFilter.name">
            <component :is="ownerFilter.icon" class="flex-shrink-0 w-4 h-4 mr-2 text-cyan-200" aria-hidden="true" />
            {{ ownerFilter.name }}
          </router-link>
        </div>
      </div>
    </nav>
  </div>
  <div class="flex flex-shrink-0 border-t border-gray-400">
    <nav class="flex-1 px-2 py-4 bg-gray-800 divide-y divide-gray-500">
      <div class="space-y-1">
        <router-link v-if="isSuperAdmin" :to="{ name: 'config-templates' }" exact-active-class="admin-links-active" class="flex items-center px-2 py-2 text-sm font-medium text-gray-300 rounded-md pointer hover:bg-gray-700 hover:text-white group">
          <CogIcon class="flex-shrink-0 w-4 h-4 mr-2 text-cyan-200" aria-hidden="true"></CogIcon>
          {{ $t("navigationBar.configTemplates") }}
        </router-link>
        <router-link v-if="isSuperAdmin" :to="{ name: 'users' }" exact-active-class="admin-links-active" class="flex items-center px-2 py-2 text-sm font-medium text-gray-300 rounded-md pointer hover:bg-gray-700 hover:text-white group">
          <UserGroupIcon class="flex-shrink-0 w-4 h-4 mr-2 text-cyan-200" aria-hidden="true"></UserGroupIcon>
          {{ $t("navigationBar.users") }}
        </router-link>
        <a href="https://www.caru-care.com/pages/academy" target="_blank" class="flex items-center px-2 py-2 text-sm font-medium text-gray-300 rounded-md pointer hover:bg-gray-700 hover:text-white group">
          <BookOpenIcon class="flex-shrink-0 w-4 h-4 mr-2 text-cyan-200" aria-hidden="true"></BookOpenIcon>
          {{ $t("navigationBar.docs") }}
        </a>
      </div>
    </nav>
  </div>
</template>
<script>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { BellIcon, UserGroupIcon, BookOpenIcon, CogIcon } from "@heroicons/vue/solid";
import { useRoute } from "vue-router";
import { quickFilterColor } from "@/lib/search";
import NavigationTree from "@/components/NavigationTree.vue";

export default {
  components: {
    BellIcon,
    UserGroupIcon,
    BookOpenIcon,
    NavigationTree,
    CogIcon
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const quickFilters = computed(() => store.state.quickFilters);
    const ownerFilters = computed(() => store.state.ownerFilters);
    const isSuperAdmin = computed(() => store.getters["auth/isSuperAdmin"]);
    const stateSummaries = computed(() => store.state.device.stateSummaryAggregation);
    store.dispatch("createOwnerFilters");

    watch(
      () => route.hash,
      (toParams) => {
        if (toParams) {
          store.dispatch("setFilter", toParams);
        }
      }
    );

    watch(
      stateSummaries,
      () => {
        let tmpFilter = JSON.parse(JSON.stringify(quickFilters.value));
        if (stateSummaries.value) {
          // TODO refactor
          tmpFilter[1].subItems[0].amount = stateSummaries.value.allFine;
          tmpFilter[1].subItems[1].amount = stateSummaries.value.offline;

          tmpFilter[1].subItems[2].subItems[0].amount = stateSummaries.value.deviceSwitchedOff;
          tmpFilter[1].subItems[2].subItems[1].amount = stateSummaries.value.deviceBatteryPowered;
          tmpFilter[1].subItems[2].subItems[2].amount = stateSummaries.value.deviceBatteryEmpty;

          tmpFilter[1].subItems[3].subItems[0].amount = stateSummaries.value.braceletBatteryLow;
          tmpFilter[1].subItems[3].subItems[1].amount = stateSummaries.value.braceletOutOfRange;

          tmpFilter[2].subItems[0].amount = stateSummaries.value.dailyCheckInEnabled;
          tmpFilter[2].subItems[0].subItems[0].amount = stateSummaries.value.dailyCheckInMissing;

          tmpFilter[2].subItems[1].amount = stateSummaries.value.dailyCheckInPaused;
          tmpFilter[2].subItems[1].subItems[0].amount = stateSummaries.value.dailyCheckInPausedUser;
          tmpFilter[2].subItems[1].subItems[1].amount = stateSummaries.value.dailyCheckInPausedDevice;

          tmpFilter[2].subItems[2].amount = stateSummaries.value.dailyCheckInOff;

          store.dispatch("setQuickFilterStats", tmpFilter);
        }
      },
      {
        immediate: true,
      }
    );

    return {
      quickFilters,
      ownerFilters,
      isFilterActive: store.getters.isFilterActive,
      isSuperAdmin,
      quickFilterColor,
    };
  },
};
</script>
<style lang="scss">
.admin-links-active {
  color: white;
  background-color: rgba(17, 24, 39, 1);

  &:hover {
    background-color: rgba(17, 24, 39, 1) !important;
  }
}
</style>
