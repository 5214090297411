<template>
  <div :style="indent">
    <div @click="toggleChildren" class="flex justify-between px-2 py-1 text-sm font-medium text-gray-300 rounded-md cursor-pointer hover:bg-gray-700 hover:text-white group 0" v-if="amount === undefined">
      <div class="flex my-auto align-middle">
        <component :is="icon" :class="['mr-2', 'flex-shrink-0', 'h-4', 'w-4', 'm-auto']" aria-hidden="true" />
        {{ label }}
      </div>
      <ChevronDownIcon :class="['justify-end', 'flex-shrink-0', 'h-4', 'w-4']" v-if="showChildren" />
      <ChevronUpIcon :class="['justify-end', 'flex-shrink-0', 'h-4', 'w-4']" v-if="!showChildren" />
    </div>

    <div v-else :class="[
      'flex justify-between px-2 py-1 text-sm font-medium rounded-md hover:bg-gray-700 text-gray-300 hover:text-white group cursor-pointer',
    ]" @click="filterClicked(query)">
      <span> {{ label }}</span>
      <span v-if="amount || amount == 0" :class="navigationTextColor(color)"> ({{ amount }})</span>
    </div>
  </div>

  <template v-if="showChildren">
    <NavigationTree v-for="node in nodes" v-bind:key="node.name" :nodes="node.subItems" :label="node.name" :depth="depth + 1" :hash="node.hash" :icon="node.icon" :color="node.color" :amount="node.amount" :query="node.query">
    </NavigationTree>
  </template>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/solid";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  name: "NavigationTree",

  components: {
    ChevronDownIcon,
    ChevronUpIcon,
  },

  props: ["label", "nodes", "depth", "hash", "icon", "amount", "color", "query"],
  data() {
    return { showChildren: true };
  },

  setup() {
    const store = useStore();
    const { t } = useI18n();

    return { store, t };
  },
  computed: {
    indent() {
      if (this.depth > 1) return { margin: `0 0 0 ${this.depth * 20}px` };

      return { margin: `0 0 0 ${this.depth * 25}px` };
    },
  },
  methods: {
    toggleChildren() {
      this.showChildren = !this.showChildren;
    },
    navigationTextColor(color) {
      if (color == "green") {
        return "text-green-300";
      } else if (color == "orange") {
        return "text-orange-300";
      } else if (color == "red") {
        return "text-red-300";
      } else if (color == "yellow") {
        return "text-yellow-100";
      } else {
        return "text-gray-300";
      }
    },

    filterClicked(filter) {

      if (this.$store.state.searchQuery) {

        let allValues = this.$store.state.searchQuery;
        let removeValues = this.$store.state.searchQuery.match(/owner:"(.*?)"/g);
        removeValues?.push(this.$store.state.searchQuery.match(/stateFilter:(.*?)\s/g));
        if (removeValues) {
          for (let value of removeValues) {
            allValues = allValues.replace(value, '');
          }
        }

        const filteredValues = allValues.length > 0 ? allValues.split(" ") : "";
        if (filteredValues.length > 0) {
          this.$store.dispatch("search", filteredValues + " " + filter);
          return;
        }
      }

      this.$store.dispatch("search", filter);

    },
  },
};
</script>
