<template>
    <div class="h-full">

        <div v-if="!isCreate" class="pb-3">
            <div class="border-b border-gray-200 rounded-lg shadow">
                <div class="flex min-w-full text-gray-500 divide-y shadow-md">
                    <div class="px-4 py-1 bg-gray-100 border whitespace-nowrap"> {{ $t("configTemplates.header.id") }}</div>
                    <div class="w-full px-4 py-1 border-b-2 border-gray-200 whitespace-nowrap "> {{ template.id ? template.id.substring(template.id.lastIndexOf(":") + 1) : "" }}</div>

                    <div class="px-4 py-1 bg-gray-100 border whitespace-nowrap"> {{ $t("configTemplates.header.lastUpdate") }}</div>
                    <div class="w-full px-4 py-1 border-b-2 border-gray-200 whitespace-nowrap "> {{ template.updatedAt ? moment(template.updatedAt).format('L') : "" }}</div>

                    <div class="px-4 py-1 bg-gray-100 border whitespace-nowrap"> {{ $t("configTemplates.header.lastUpdateBy") }}</div>
                    <div class="w-full px-4 py-1 border-b-2 border-gray-200 whitespace-nowrap "> {{ template.updater.name ? template.updater.name : "" }}</div>

                    <div class="px-4 py-1 bg-gray-100 border whitespace-nowrap"> {{ $t("configTemplates.header.owner") }}</div>
                    <div class="w-full px-4 py-1 border-b-2 border-gray-200 whitespace-nowrap "> {{ template.owner ? template.owner.name : "" }}</div>

                </div>
            </div>
        </div>

        <div class="border-b border-gray-200 rounded-lg shadow">
            <div class="flex min-w-full">
                <div class="w-1/6 px-4 py-2 text-gray-500 bg-gray-100 border shadow-md whitespace-nowrap"> {{ $t("configTemplates.header.name") }} *</div>
                <input v-model="name" type="text" :placeholder="$t('configTemplates.header.name')" :class='["text-sm w-full  py-2 border-b  whitespace-nowrap focus:shadow-md focus:outline-none focus:ring-0", name?.length == 0 ? "border-red-600 focus:border-red-600 border-b" : "focus:border-gray-200 focus:bg-gray-50  border-gray-200"]' />
            </div>
            <div v-if="isCreate" class="flex min-w-full">
                <div class="w-1/6 px-4 py-2 text-gray-500 bg-gray-100 border shadow-md whitespace-nowrap"> {{ $t("configTemplates.header.owner") }} *</div>
                <div :class='["z-50 w-full border-b border-l border-r whitespace-nowrap", !selectedOwner && "border-red-500 "]'>
                    <Combobox as="div" v-model="selectedOwner">
                        <div :class='["relative border-t ", !selectedOwner && "border-red-500"]'>
                            <div class="relative flex w-full overflow-hidden text-left bg-white cursor-default py-auto focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                <ComboboxInput class="w-full py-2 pl-3 pr-10 text-sm leading-3 text-gray-900 border-none focus:ring-0" @change="ownerQuery = $event.target.value" :display-value="(owner) => owner?.name" />
                                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                                    <ChevronDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                                </ComboboxButton>
                            </div>

                            <ComboboxOptions v-if="filteredOwner.length > 0" class="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none">
                                <ComboboxOption v-for="owner in filteredOwner" :key="owner.id" :value="owner" as="template" v-slot="{ active, selected }">
                                    <li class="relative py-2 pl-10 pr-4 cursor-default select-none " :class="{
                                        'bg-teal-600 text-white': active,
                                        'text-gray-900': !active,
                                    }">
                                        <span class="block truncate" :class="{ 'font-medium': selected, 'font-normal': !selected }">
                                            {{ owner.name }}
                                        </span>
                                        <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3" :class="{ 'text-white': active, 'text-teal-600': !active }">
                                            <CheckIcon class="w-5 h-5" aria-hidden="true" />
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
            </div>
            <div class="flex min-w-full">
                <div class="w-1/6 px-4 py-2 text-gray-500 bg-gray-100 border shadow-md whitespace-nowrap"> {{ $t("configTemplates.header.applier") }}</div>
                <div class="z-40 w-full border-r-2 whitespace-nowrap">
                    <Combobox v-model="selectedAppliers" multiple as="div">
                        <div class="relative">
                            <div class="relative flex w-full overflow-hidden text-left bg-white cursor-default py-auto focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                <div v-if="selectedAppliers.length > 0" class="flex py-2 pl-3">{{ selectedAppliers.map((applier) => applier.name).join(", ") }}</div>
                                <ComboboxInput class="w-full py-2 pl-3 pr-10 leading-3 text-gray-900 border-none focus:ring-0" :displayValue="(applier) => applier.name" @change="query = $event.target.value" />
                                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                                    <ChevronDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                                </ComboboxButton>
                            </div>

                            <transition leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" @after-leave="query = ''">
                                <ComboboxOptions class="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none ">
                                    <div v-if="filteredAppliers.length === 0 && query !== ''" class="relative px-4 py-2 text-gray-700 cursor-default select-none">
                                        {{ $t("error.notFound") }}
                                    </div>

                                    <ComboboxOption v-for="applier in filteredAppliers" as="template" :key="applier.id" :value="applier" v-slot="{ selected, active }">
                                        <li class="relative py-2 pl-10 pr-4 cursor-default select-none " :class="{
                                            'bg-teal-600 text-white': active,
                                            'text-gray-900': !active,
                                        }">
                                            <span class="block truncate" :class="{ 'font-medium': selected, 'font-normal': !selected }">
                                                {{ applier.name }}
                                            </span>
                                            <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3" :class="{ 'text-white': active, 'text-teal-600': !active }">
                                                <CheckIcon class="w-5 h-5" aria-hidden="true" />
                                            </span>
                                        </li>
                                    </ComboboxOption>
                                </ComboboxOptions>
                            </transition>
                        </div>
                    </Combobox>
                </div>
            </div>

            <div class="flex min-w-full h-80">
                <div class="w-1/6 px-4 py-3 text-gray-500 bg-gray-100 border shadow-md whitespace-nowrap">
                    <div class="flex my-auto">
                        {{ $t("configTemplates.header.template") }}

                        <div class="relative px-2 text-sm font-semibold text-left text-gray-900 group w-fit">
                            <InformationCircleIcon class="w-5 text-gray-500 group hover:text-cyan-800" aria-hidden="true" />
                            <div class="absolute z-20 invisible max-w-sm px-3 py-3 mt-2 text-gray-500 break-all origin-top bg-white rounded-md shadow-lg group-hover:visible ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {{ $t("configTemplates.templateDescription") }}
                            </div>
                        </div>
                    </div>
                </div>

                <JsonEditorVue class='w-full border-b-2 border-gray-200 whitespace-nowrap' v-model="templateBody" v-bind="{ mode: 'text', mainMenuBar: false }" />
            </div>

            <div class="flex min-w-full h-fit">
                <div class="w-1/6 px-4 py-3 text-gray-500 bg-gray-100 border shadow-md whitespace-nowrap"> {{ $t("configTemplates.header.preview") }}</div>
                <div class="w-full border-b-2 border-gray-200 whitespace-nowrap h-fit">
                    <div class="flex min-w-full divide-y " v-for="(  placeholder, index  ) in    placeholders  " :key="index">
                        <div class="w-1/4 px-4 py-2 text-gray-500 bg-gray-100 border shadow-md whitespace-nowrap"> {{ placeholder.placeholder }} </div>
                        <input v-model="placeholder.value" :placeholder='$t("configTemplates.header.example")' class="w-full px-4 py-2 border-b-2 border-gray-200 whitespace-nowrap focus:shadow-md focus:border-gray-200 focus:border-rounded-lg focus:bg-gray-50 focus:outline-none focus:ring-0" />
                    </div>
                    <JsonEditorVue class='w-full border-b-2 border-gray-200 whitespace-nowrap' v-model="exampleTemplate" v-bind="{ mode: 'text', mainMenuBar: false, readOnly: true }" />
                </div>
            </div>
        </div>

        <div class="flex justify-end flex-shrink-0 py-4">
            <button type="button" class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="$emit('cancel')">
                {{ $t("common.action.cancel") }}
            </button>

            <button v-show="!isCreate" type="submit" class="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50" @click="confirmDelete = true">
                {{ $t("common.action.delete") }}
            </button>

            <button type="submit" :disabled="!isSaveAllowed" class="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50" @click="save">
                {{ $t("common.action.save") }}
            </button>
        </div>

    </div>

    <SimpleAlert @cancel="confirmDelete = false" @approve="deleteTemplate" :open="confirmDelete" :title="$t('alert.deleteTemplateTitle')" :description="$t('alert.deleteTemplateDescription')" :action="$t('common.action.delete')" type="error"></SimpleAlert>
</template>

<script>
import { useI18n } from "vue-i18n";
import { computed, ref, watch } from "vue";
import { InformationCircleIcon, CheckIcon, ChevronDownIcon } from '@heroicons/vue/outline';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/vue";
import { useStore } from 'vuex';
import SimpleAlert from "@/components/modals/SimpleAlert";
import JsonEditorVue from 'json-editor-vue'
import moment from "moment";

export default {
    components: {
        JsonEditorVue,
        InformationCircleIcon,
        CheckIcon,
        ChevronDownIcon,
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxOption,
        ComboboxOptions,
        SimpleAlert
    },
    name: "EditConfigTemplate",

    props: ["template", "isCreate"],
    emits: ["cancel"],

    setup(props, { emit }) {

        moment.locale('de-ch');
        const { t } = useI18n();

        const store = useStore();
        const allTenants = computed(() => store.getters.tenants);

        const selectedAppliers = ref(props.template.applierIds ? allTenants.value.filter((tenant) => props.template.applierIds.includes(tenant.id)) : [])
        const query = ref('')
        const filteredAppliers = computed(() =>
            query.value === ''
                ? allTenants.value
                : allTenants.value.filter((tenants) => {
                    return tenants.name.toLowerCase().includes(query.value.toLowerCase())
                })
        )

        const selectedOwner = ref("")
        const ownerQuery = ref("")
        const filteredOwner = computed(() =>
            ownerQuery.value === ''
                ? allTenants.value
                : allTenants.value.filter((tenants) => {
                    return tenants.name.toLowerCase().includes(ownerQuery.value.toLowerCase())
                })
        )
        const isSaveAllowed = computed(() => {

            if (!props.isCreate && (name.value == '' || !isValidJSON(templateBody.value))) {
                return false;
            }
            if (props.isCreate && (name.value == '' || !selectedOwner.value || !isValidJSON(templateBody.value))) {
                return false;
            }
            return true;
        });

        const isValidJSON = (toTest) => {
            try {
                JSON.parse(toTest);
            } catch (e) {
                return false;
            }
            return true;
        }

        const getPlaceholders = (template) => {

            if (((JSON.stringify(template).split('$') || []).length - 1) % 2 == 0) {
                const regex = "\\$([^$]+)\\$";
                return [...new Set([...JSON.stringify(template).matchAll(regex)].map((placeholder) => placeholder[0]))].map((plchldr) => ({ placeholder: plchldr, value: '' }));
            }
            return "";
        }

        const templateBody = ref(props.template.template ? JSON.parse(props.template.template) : {})
        const exampleTemplate = ref(templateBody.value)
        const placeholders = ref(getPlaceholders(templateBody))
        const name = ref(props.template && props.template.name ? props.template.name : "")

        const confirmDelete = ref(false);

        const parseTemplateBody = () => {

            let body;
            if (typeof templateBody.value === "string") {
                body = JSON.parse(templateBody.value);
            } else {
                body = templateBody.value;
            }

            for (const { placeholder, value } of placeholders.value) {
                if (placeholder && value != '') {
                    const parsed = JSON.stringify(body).replaceAll(placeholder, value);
                    exampleTemplate.value = JSON.parse(parsed);
                    body = exampleTemplate.value;
                }
            }

            if (placeholders.value.filter((y) => y.value != '').length == 0) exampleTemplate.value = JSON.parse(templateBody.value);

        }

        watch(templateBody, () => {
            placeholders.value = getPlaceholders(templateBody.value)
        })

        watch(placeholders, () => {
            if (placeholders.value)
                parseTemplateBody()
        }, { deep: true })

        const save = async () => {

            let ret = false;

            let body = ""
            if (typeof templateBody.value === "string") {
                body = templateBody.value;
            } else {
                body = JSON.stringify(templateBody.value);
            }


            if (props.isCreate) {
                ret = await store.dispatch("device/createConfigTemplate", {
                    template: {
                        name: name.value,
                        ownerId: selectedOwner.value.id,
                        applierIds: selectedAppliers.value.map((applier) => applier.id),
                        template: body
                    },
                });
            } else {
                ret = await store.dispatch("device/updateConfigTemplate", {
                    template: {
                        id: props.template.id,
                        name: name.value,
                        applierIds: selectedAppliers.value.map((applier) => applier.id),
                        template: body
                    },
                });
            }
            if (ret) {
                await store.dispatch("showNotification", {
                    title: t("notifications.mutations.success"),
                    text: t("notifications.mutations.templateStored"),
                    type: "info",
                });
                await store.dispatch("device/queryConfigTemplates");

                emit('cancel')
            } else {
                await store.dispatch("showNotification", {
                    title: t("notifications.mutations.error"),
                    text: t("notifications.mutations.templateStoredFailure"),
                    type: "error",
                });
            }
        }

        const deleteTemplate = async () => {
            const ret = await store.dispatch("device/deleteConfigTemplate", { templateId: props.template.id });
            if (ret) {
                await store.dispatch("showNotification", {
                    title: t("notifications.mutations.success"),
                    text: t("notifications.mutations.templateStored"),
                    type: "info",
                });
                await store.dispatch("device/queryConfigTemplates");
                emit('cancel')
            } else {
                await store.dispatch("showNotification", {
                    title: t("notifications.mutations.error"),
                    text: t("notifications.mutations.templateStoredFailure"),
                    type: "error",
                });
            }
        }

        return {
            allTenants,
            name,
            placeholders,
            templateBody,
            exampleTemplate,
            selectedAppliers,
            getPlaceholders,
            filteredAppliers,
            filteredOwner,
            selectedOwner,
            query,
            deleteTemplate,
            confirmDelete,
            save,
            moment,
            isSaveAllowed
        };
    },
};
</script>
