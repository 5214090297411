import { queryUsers} from "@/api/users";

const state = () => ({
  all: new Map(),
  current: {},
});

const getters = {};

const actions = {
  async getUsers({ state, commit }) {
    if (state.all.size === 0) {
      const users = await queryUsers();
      commit("setUsers", users);
    }
  },
};

const mutations = {
  setUsers(state, users) {
    state.all.clear();
    users.forEach((user) => {
      state.all.set(user.id, user);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
