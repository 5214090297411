<template>
  <div class="pt-8 mx-auto px-4">
    <h1 class="flex-1 text-2xl font-bold text-gray-900">{{ $t("user.listPageTitle") }}</h1>
    <div class="flex flex-col">
      <div class="py-2 align-middle inline-block min-w-full">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ $t("user.header.name") }}
              </th>
              <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
              >
                {{ $t("user.header.tenant") }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ $t("user.header.role") }}
              </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="[id, user] in users" :key="id">
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="flex flex-col">
                  <div class="text-sm font-medium text-gray-900">
                    {{ user.name }}
                  </div>
                  <div class="text-sm text-gray-500">
                    {{ user.email }}
                  </div>
                </div>
              </td>
              <td class="pl-6 pr-3 py-4 whitespace-nowrap text-sm">
                <div class="flex flex-col gap-y-2">
                  <div
                      v-for="tenantRole in user.tenants"
                      :key="`${tenantRole.tenant.id}-${tenantRole.role}`"
                      class="font-medium text-right"
                  >
                    {{ tenantRole.tenant.name }}
                  </div>
                </div>
              </td>
              <td class="pr-6 pl-3 py-4 whitespace-nowrap text-sm text-gray-500">
                <div class="flex flex-col gap-y-2">
                  <div v-for="tenantRole in user.tenants" :key="`${tenantRole.tenant.id}-${tenantRole.role}`">
                    {{ tenantRole.role }}
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  setup() {
    const store = useStore();
    const users = computed(() => store.state.users.all);
    store.dispatch("loadTenants");
    store.dispatch("users/getUsers");

    return {
      users,
    };
  },
};
</script>
