<template>
  <td class="w-5 px-4 py-2">
    <div ref="onlineTooltipTrigger" :class="[device.isOnline ? 'bg-green-500' : 'bg-red-500', 'w-2.5', 'h-2.5', 'rounded-full', 'group ']" aria-hidden="true" />
    <div ref="onlineTooltipTarget" class="inline-block absolute invisible z-10 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
      {{ device.isOnline ? "Online" : "Offline" }}
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
  </td>
  <td class="px-1 py-2 font-medium text-left w-28">
    {{ device.serialNumber }}
  </td>
  <td class="px-1 py-2 font-medium text-left w-28">
    {{ device.owner.name }}
  </td>
  <td class="px-1 py-2 font-medium text-left w-28">
    {{ getDeviceControllerID(device) }}
  </td>
  <td class="px-1 py-2 font-light truncate">
    {{ JSON.parse(device.configV2.data).accessories.bracelets.map(bracelet => bracelet.serial_number).join(", ") }}
  </td>
  <td class="w-8">
    <CalendarIcon ref="dailyCheckInStatusTooltipTrigger" :class="[device.isOnline ? '' : 'invisible', 'h-6', 'w-6', 'rounded-full', ...dailyCheckInStatusColor(device), 'px-1']" aria-hidden="true" />
    <div ref="dailyCheckInStatusTooltipTarget" v-if="device.isOnline" class="inline-block absolute invisible z-10 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
      {{ $t("device.tooltip.dailyCheckIn") }}
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
  </td>
  <td class="hidden w-8">
    <BraceletIcon ref="braceletPresenceStatusTooltipTrigger" v-if="device.bracelets.length" :class="[device.isOnline ? '' : 'invisible', 'h-6', 'w-6', 'rounded-full', ...braceletPresenceStatus(device), 'px-1']" aria-hidden="true" />
    <div ref="braceletPresenceStatusTooltipTarget" v-if="device.bracelets.length && device.isOnline" class="inline-block absolute invisible z-10 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
      {{ $t("device.tooltip.braceletPresenceStatus") }}
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
  </td>
  <td class="hidden w-8">
    <div ref="braceletBatteryStatusTrigger" v-if="device.bracelets.length">
      <BraceletBatteryIcon :class="[device.isOnline ? '' : 'invisible', 'h-6', 'w-6', 'rounded-full', ...braceletBatteryStatus(device), 'px-1']" aria-hidden="true" />
    </div>
    <div ref="braceletBatteryStatusTarget" v-if="device.bracelets.length && device.isOnline" class="inline-block absolute invisible z-10 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
      {{ $t("device.tooltip.braceletBatteryStatus") }}
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
  </td>
  <!-- hidden no wifi state currently available -->
  <td class="hidden w-8">
    <WifiIcon ref="wifiStatusTooltipTrigger" :class="[device.isOnline && device.telemetry.wifi ? '' : 'invisible', 'h-6', 'w-6', 'rounded-full', ...wifiStatus(device), 'px-1']" aria-hidden="true" />
    <div ref="wifiStatusTooltipTarget" v-if="device.isOnline && device.telemetry.wifi" class="inline-block absolute invisible z-10 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
      {{ $t("device.tooltip.wifiStatus") }}
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
  </td>
  <td class="w-8">
    <CloudIcon ref="presenceStatusTooltipTrigger" :class="[device.isOnline ? '' : 'invisible', 'h-6', 'w-6', 'rounded-full', ...cloudPresenceStatusColor(device), 'px-1']" aria-hidden="true" />
    <div ref="presenceStatusTooltipTarget" v-if="device.isOnline" class="inline-block absolute invisible z-10 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
      {{ $t("device.tooltip.presenceStatus") }}
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
  </td>
  <td class="hidden w-8">
    <BatteryIcon ref="powerSupplyStatusTooltipTrigger" :class="[device.isOnline ? '' : 'invisible', 'h-6', 'w-6', 'rounded-full', ...powerSupplyStatus(device), 'px-1']" aria-hidden="true" />
    <div ref="powerSupplyStatusTooltipTarget" v-if="device.isOnline" class="inline-block absolute invisible z-10 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
      {{ $t("device.tooltip.powerSupplyStatus") }}
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
  </td>
  <td class="w-16 px-1 py-2 text-sm font-light ">
    {{ device.firmware ? device.firmware.version : "" }}
  </td>
</template>

<script>
import {
  overallBraceletStatusColor,
  wifiStatusColor,
  cloudPresenceStatusColor,
  braceletPresenceStatusColor,
  powerSupplyStatusColor,
  braceletBatteryStatusColor, dailyCheckInStatusColor
} from "@/lib/statusColors";
import { CloudIcon, WifiIcon, CalendarIcon } from "@heroicons/vue/outline";
import { BraceletIcon, BatteryIcon, BraceletBatteryIcon } from "@/components/icons";
import { Tooltip } from "flowbite";
import { onMounted, ref } from "vue";

export default {
  components: {
    WifiIcon,
    CloudIcon,
    BraceletIcon,
    BraceletBatteryIcon,
    CalendarIcon,
    BatteryIcon
  },
  name: "DeviceListItem",
  props: {
    device: { required: true },
    isSuperAdmin: { required: true },
  },
  setup(props) {
    const onlineTooltipTarget = ref(null);
    const onlineTooltipTrigger = ref(null);

    const braceletPresenceStatusTooltipTrigger = ref(null);
    const braceletPresenceStatusTooltipTarget = ref(null);

    const dailyCheckInStatusTooltipTarget = ref(null);
    const dailyCheckInStatusTooltipTrigger = ref(null);

    const braceletBatteryStatusTrigger = ref(null);
    const braceletBatteryStatusTarget = ref(null);

    const wifiStatusTooltipTrigger = ref(null);
    const wifiStatusTooltipTarget = ref(null);

    const presenceStatusTooltipTrigger = ref(null);
    const presenceStatusTooltipTarget = ref(null);

    const powerSupplyStatusTooltipTrigger = ref(null);
    const powerSupplyStatusTooltipTarget = ref(null);


    onMounted(() => {
      const options = {
        placement: 'bottom',
        triggerType: 'hover',
      }
      new Tooltip(onlineTooltipTarget.value, onlineTooltipTrigger.value, options);

      if (props.device.isOnline) {
        new Tooltip(wifiStatusTooltipTarget.value, wifiStatusTooltipTrigger.value, options);
        new Tooltip(presenceStatusTooltipTarget.value, presenceStatusTooltipTrigger.value, options);
        new Tooltip(powerSupplyStatusTooltipTarget.value, powerSupplyStatusTooltipTrigger.value, options);
        new Tooltip(dailyCheckInStatusTooltipTarget.value, dailyCheckInStatusTooltipTrigger.value, options);

        if (props.device.bracelets.length) {
          new Tooltip(braceletPresenceStatusTooltipTarget.value, braceletPresenceStatusTooltipTrigger.value, options);
          new Tooltip(braceletBatteryStatusTarget.value, braceletBatteryStatusTrigger.value, options);
        }
      }
    })

    const getDeviceControllerID = (device) => {
      return JSON.parse(device.configV2.data).alarm_app.destinations.filter(destination => destination.scaip).map(destination => destination.scaip.controller_id).join(", ")
    }

    return {
      onlineTooltipTrigger,
      onlineTooltipTarget,
      braceletPresenceStatusTooltipTrigger,
      braceletPresenceStatusTooltipTarget,
      dailyCheckInStatusTooltipTarget,
      dailyCheckInStatusTooltipTrigger,
      braceletBatteryStatusTrigger,
      braceletBatteryStatusTarget,
      wifiStatusTooltipTrigger,
      wifiStatusTooltipTarget,
      presenceStatusTooltipTarget,
      presenceStatusTooltipTrigger,
      powerSupplyStatusTooltipTarget,
      powerSupplyStatusTooltipTrigger,
      overallBraceletStatus: overallBraceletStatusColor,
      wifiStatus: wifiStatusColor,
      cloudPresenceStatusColor,
      braceletPresenceStatus: braceletPresenceStatusColor,
      powerSupplyStatus: powerSupplyStatusColor,
      braceletBatteryStatus: braceletBatteryStatusColor,
      dailyCheckInStatusColor,
      getDeviceControllerID,
    };
  },
};
</script>
