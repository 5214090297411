import userQuery from "./graphql/users_query.graphql";
import { runQuery } from "@/api/query";

const flattenUsers = (users) => {
  return users.edges.map((edge) => {
    let user = edge.node;
    user.tenants = user.tenants.edges.map((edge) => edge.node);
    return user;
  });
};

export const queryUsers = async (userIds) => {
  const result = await runQuery(userQuery, {
    userIds: userIds,
  });

  return result ? flattenUsers(result.users) : [];
};
