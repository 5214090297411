import store from "@/store";

export const USER_PERMISSIONS = {
	VIEWER: "VIEWER",
	ADMIN: "ADMIN"
}

export const hasDeviceTenantPermission = (permission) => {
    const tenant = store.state.device.current.owner.slug;
    const tenantPermission = tenant + ":" + permission;
    return store.getters['auth/isSuperAdmin']
        || store.getters['auth/userRoles'].find(userPerm => userPerm === tenantPermission) !== undefined;
}
